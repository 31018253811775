<template>
  <div class="py-10 max-w-7xl mx-auto sm:px-6 lg:px-8">
    <h1 class="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">Profile edit</h1>
  </div>
  <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">

    <div class="bg-white shadow sm:rounded-lg">
      <div class="px-4 py-5 sm:p-6">
        <h3 class="text-lg leading-6 font-medium text-gray-900">
          Personal information
        </h3>
    <Form
      @submit="update()"
      class="space-y-6"
    >

      <div class="mt-6 space-y-6 sm:space-y-5">
        <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
          <label for="name" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
            Name
          </label>
          <div class="mt-1 sm:mt-0 sm:col-span-2">
            <Field
              id="name"
              name="name"
              v-model="userData.data.full_name"
              type="text"
              :rules="isRequired"
              class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
            <ErrorMessage name="name" class="mt-2 text-sm text-red-600" />
          </div>
        </div>
      </div>


      <div class="mt-6 space-y-6 sm:space-y-5">
        <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
          <label for="timezone" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
            Timezone
          </label>
          <div class="mt-1 sm:mt-0 sm:col-span-2">
            <select v-model="userData.data.timezone" id="timezone" name="timezone" class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
              <option>Default</option>
              <option v-for="timezone in timezones" :value="timezone.name" v-bind:key="timezone">
                {{timezone.name}} - {{timezone.gmt}}
              </option>
            </select>
          </div>
        </div>
      </div>

      <div class="mt-6 space-y-6 sm:space-y-5">
        <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
          <label for="animation" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
            Performance - animations
          </label>
          <div class="mt-1 sm:mt-0 sm:col-span-2">
            <select v-model="userData.data.performance" id="animation" name="animation" class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
              <option v-for="perf in performance" :value="perf.value" v-bind:key="perf">
                {{perf.name}}
              </option>
            </select>
          </div>
        </div>
      </div>

      <div class="mt-6 sm:flex sm:items-start sm:justify-between sm:border-t sm:border-gray-200 pt-2">
        <div class="max-w-xl text-sm text-gray-500">
        </div>
        <div class="mt-5 sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:flex sm:items-center">
          <button
            :disabled="lockButtons"
            class="disabled:opacity-50 inline-flex items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
          >
            Save
          </button>
        </div>
      </div>
    </Form>

      </div>
    </div>

  </div>

  <div class="max-w-7xl mx-auto sm:px-6 lg:px-8 mt-10">

    <div class="bg-white shadow sm:rounded-lg">
      <div class="px-4 py-5 sm:p-6">
        <h3 class="text-lg leading-6 font-medium text-gray-900">
          Password
        </h3>
        <Form
          @submit="updatePassword()"
          class="space-y-6"
        >
          <div class="mt-6 space-y-6 sm:space-y-5">
            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label for="name" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                New password
              </label>
              <div class="mt-1 sm:mt-0 sm:col-span-2">
                <Field
                  id="password"
                  name="password"
                  v-model="userPassword"
                  type="password"
                  :rules="isRequired"
                  class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                <ErrorMessage name="name" class="mt-2 text-sm text-red-600" />
              </div>
            </div>
          </div>

          <div class="mt-6 sm:flex sm:items-start sm:justify-between sm:border-t sm:border-gray-200 pt-2">
            <div class="max-w-xl text-sm text-gray-500">
            </div>
            <div class="mt-5 sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:flex sm:items-center">
              <button
                :disabled="lockButtons"
                class="disabled:opacity-50 inline-flex items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
              >
                Save password
              </button>
            </div>
          </div>

        </Form>
      </div>
    </div>
  </div>

    <div class="max-w-7xl mx-auto sm:px-6 lg:px-8 mt-10 mb-10">
    <div class="bg-white shadow sm:rounded-lg">
      <div class="px-4 py-5 sm:p-6">
        <h3 class="text-lg leading-6 font-medium text-gray-900">
          Status page
        </h3>
        <div class="mt-2 sm:flex sm:items-start sm:justify-between">
          <div class="max-w-xl text-sm text-gray-500">
            <p>
              Share your Status page with your peers or family at home, so they know when they can expect you are done.
            </p>
            <p class="mt-2">
              URL: <span class="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-gray-100 text-gray-800">
                https://app.getkairo.com/status/{{statusPage.id}}
              </span>
              <a :href="'https://app.getkairo.com/status/' + statusPage.id" target="_blank" class="inline-block h-4">
                <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z"></path><path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z"></path></svg>
              </a>
            </p>
          </div>
        </div>

        <div class="mt-6 space-y-6 sm:space-y-5">
          <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label for="status_page_title" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
              Status
            </label>
            <div class="mt-1 sm:mt-0 sm:col-span-2">

              <!-- Enabled: "bg-indigo-600", Not Enabled: "bg-gray-200" -->
              <button @click="statusPage.active = !statusPage.active" type="button"
                class="bg-gray-200 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                :class="{'bg-indigo-600': statusPage.active}"
                role="switch" aria-checked="false">
                <span class="sr-only">Use setting</span>
                <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
                <span class="translate-x-0 pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                  :class="{'translate-x-5': statusPage.active}"
                >
                  <!-- Enabled: "opacity-0 ease-out duration-100", Not Enabled: "opacity-100 ease-in duration-200" -->
                  <span class="opacity-0 ease-out duration-100 absolute inset-0 h-full w-full flex items-center justify-center transition-opacity"
                    aria-hidden="true"
                    :class="{'opacity-100 ease-in duration-200': !statusPage.active}"
                  >
                    <svg class="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
                      <path d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </span>
                                <!-- Enabled: "opacity-100 ease-in duration-200", Not Enabled: "opacity-0 ease-out duration-100" -->
                  <span class="opacity-0 ease-out duration-100 absolute inset-0 h-full w-full flex items-center justify-center transition-opacity"
                    :class="{'opacity-100 ease-in duration-200': statusPage.active}"
                    aria-hidden="true">
                    <svg class="h-3 w-3 text-indigo-600" fill="currentColor" viewBox="0 0 12 12">
                      <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                    </svg>
                  </span>
                </span>
              </button>
            </div>
          </div>
        </div>

        <div class="mt-6 space-y-6 sm:space-y-5">
          <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label for="status_page_title" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
              Title
            </label>
            <div class="mt-1 sm:mt-0 sm:col-span-2">
              <input type="text" v-model="statusPage.title" name="status_page_title" id="status_page_title" class="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md">
            </div>
          </div>
        </div>

        <div class="mt-6 space-y-6 sm:space-y-5">
          <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label for="status_page_image" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
              Image
            </label>
            <div class="mt-1 sm:mt-0 sm:col-span-2">
              <input type="text" v-model="statusPage.image" name="status_page_image" id="status_page_image" class="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md">
            </div>
          </div>
        </div>

        <div class="mt-6 space-y-6 sm:space-y-5">
          <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label for="status_page_deep_work_message" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
              Deep Work message
            </label>
            <div class="mt-1 sm:mt-0 sm:col-span-2">
              <input type="text" v-model="statusPage.deepWorkMessage" name="status_page_deep_work_message" id="status_page_deep_work_message" class="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md">
            </div>
          </div>
        </div>

        <div class="mt-6 space-y-6 sm:space-y-5">
          <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label for="status_page_emergency_message" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
              Emergency message
            </label>
            <div class="mt-1 sm:mt-0 sm:col-span-2">
              <input type="text" v-model="statusPage.emergencyMessage" name="status_page_emergency_message" id="status_page_emergency_message" class="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md">
            </div>
          </div>
        </div>

        <div class="mt-6 sm:flex sm:items-start sm:justify-between sm:border-t sm:border-gray-200 pt-2">
          <div class="max-w-xl text-sm text-gray-500">
          </div>
          <div class="mt-5 sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:flex sm:items-center">
            <button @click="statusPageUpdate" type="button" class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm">
              Save
            </button>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
  import {mapGetters, mapActions} from "vuex";
  import { Field, Form, ErrorMessage } from 'vee-validate';
  import { v4 as uuidv4 } from 'uuid';
  import {
    getStatusPage,
    createStatusPage,
    updateStatusPage
  } from "../models/StatusPagesModel";
  import { useToast } from "vue-toastification";
  import {timezones} from '../utils/timezones.js';

  export default {
    name: "Profile",
    components: {
      Field,
      Form,
      ErrorMessage
    },
    setup() {
      const toast = useToast();
      return { toast };
    },
    data() {
      return {
        lockButtons: false,
        userData: {
          data: {
            full_name: "",
            timezone: "",
            performance: ""
          }
        },
        performance: [
          {
            name: 'All animations',
            value: 'high'
          },
          {
            name: 'Some animations',
            value: 'medium'
          },
          {
            name: 'No animations',
            value: 'low'
          }
        ],
        userPassword: '',
        statusPage: {},
        timezones,
      };
    },
    methods: {
      ...mapActions("auth", [
        "updateUserAccount",
        "updateUserPassword"
      ]),
      isRequired(value) {
        return value ? true : 'This field is required';
      },
      update() {
        this.lockButtons = true;
        let data = this.userData;
        data.email = this.currentUser.email;
        this.updateUserAccount(data)
            .then(() => {
              if (data.data.full_name !== this.currentUser.user_metadata.full_name) {
                this.currentUser.user_metadata.full_name;
              }
              if (data.data.timezone !== this.currentUser.user_metadata.timezone) {
                this.currentUser.user_metadata.timezone;
              }
              if (data.data.performance !== this.currentUser.user_metadata.performance) {
                this.currentUser.user_metadata.performance;
              }
              this.toast.success(`You have updated your profile.`);
              this.lockButtons = false;
            })
            .catch(() => {
              this.toast.error(`Failed to update user account.`);
              this.lockButtons = false;
            });
      },
      updatePassword() {
        this.lockButtons = true;
        let data = {};
        data.email = this.currentUser.email;
        data.password = this.userPassword;
        this.updateUserPassword(data)
            .then(() => {
              this.toast.success(`You have updated your password.`);
              this.lockButtons = false;
            })
            .catch(() => {
              this.toast.error(`Failed to update user account.`);
              this.lockButtons = false;
            });
      },
      statusPageAddDefault() {
        let statusPage = {
          title: "My Status",
          id: uuidv4(),
          image: '',
          color: '',
          deepWorkMessage: 'Please do not disturb me, unless it is an emergency.',
          emergencyMessage: 'Call.',
          active: false
        };

        this.statusPage = statusPage;
        createStatusPage(statusPage)
          .then((res) => {
            this.statusPage.refID = res.ref.value.id;
          })
          .catch(() => {
            this.toast.error(`We are not able to create the default Status Page.`)
          });
      },
      statusPageUpdate() {
        delete this.statusPage.owner;
        updateStatusPage(this.statusPage.refID, this.statusPage)
            .then(() => {
              this.toast.success(`Status page has been updated.`)
            })
            .catch(() => {
              this.toast.error(`Something went wrong. We were not able to save the Status page updates into DB.`)
            });
      }
    },
    computed: {
      ...mapGetters("auth", ["currentUser"])
    },
    mounted() {
      // get user's status page
      this.userData.data.full_name = JSON.parse(JSON.stringify(this.currentUser.user_metadata.full_name));
      if (typeof this.currentUser.user_metadata.timezone !== 'undefined') {
        this.userData.data.timezone = JSON.parse(JSON.stringify(this.currentUser.user_metadata.timezone));
      } else {
        this.userData.data.timezone = 'Default';
      }
      if (typeof this.currentUser.user_metadata.performance !== 'undefined') {
        this.userData.data.performance = JSON.parse(JSON.stringify(this.currentUser.user_metadata.performance));
      } else {
        this.userData.data.performance = 'high';
      }

      getStatusPage()
          .then(resp => {
            let statusPagesData = resp.data;
            let statusPage = null;
            statusPagesData.forEach(function (statusPageData) {
              statusPage = statusPageData.data;
              statusPage.refID = statusPageData.ref.value.id;
            });
            if (statusPage === null) {
              // no board found
              // create default and save to db
              this.statusPageAddDefault(true);
            } else {
              // sanitize board
              // board = this.boardSanitize(board);
              // this.$store.commit('boardAdd', board);
              // this.$emit('updateBG', board.color);
              this.statusPage = statusPage;
            }
          })
          .catch(() =>
              this.toast.error(`We are not able to fetch Status page from the database.`)
          );
    }
  };
</script>
